@import "_colors";

.paragraphs-item-paragraph-alert {
  &.alert {
    margin-bottom: 0;

    &.alert-dismissible {
      .close {
        right: 10px;
        top: 2rem;
        font-size: xx-large;
      }

      .field-name-field-paragraph-alert-content {
        padding-right: 1.5rem;
      }
    }

    &.alert-sticky {
      position: fixed;
      bottom: 0;
      width: auto;
    }

    &.alert-default-light {
      background-color: @default-light;
    }

    &.alert-default-dark {
      background-color: @default-dark;

      .field-item {
        color: white;

        a {
          color: white;
        }
      }
    }

    &.alert-info {
      background-color: @info;
    }

    &.alert-success {
      background-color: @success;
    }

    &.alert-warning {
      background-color: @warning;
    }

    &.alert-error {
      background-color: @error;
    }

    .field-name-field-paragraph-alert-content .field-items .field-item {
      background-color: unset;
    }

    a {
      color: #000;
    }

    &.alert-content--padding-0 {
      padding: 0 0;
    }

    &.alert-content--padding-10 {
      padding: 0 10%;
      @media (max-width: 768px) {
        padding: 0 5%;
      }
    }

    &.alert-content--padding-20 {
      padding: 0 20%;
      @media (max-width: 768px) {
        padding: 0 10%;
      }
      @media (max-width: 480px) {
        padding: 0 5%;
      }
    }

    &.alert-content--padding-67 {
      padding: 0 67px;
      @media (max-width: 980px) {
        padding: 0;
      }
    }
  }
}

#content .paragraphs-item-paragraph-alert .field-name-field-paragraph-alert-content .field-items .field-item p {
  margin: 0;
}
